import "./components/preloader";
import "./components/joomla";
// import "./components/progress-to-top";
import "./components/reveal-effects";
import "./components/anim-menu-btn";
import "./components/flexi-header";
import StickyHeader from "./components/sticky-header";
import "./components/tabs";
import "./components/accordion";
import "./components/modal-window";
import "./components/modal-video";

import Swiper from "swiper";
import { Navigation, Pagination } from "swiper/modules";

const handleScrollToTop = (event) => {
  event.preventDefault();
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
  return false;
};

// init
(() => {
  new Swiper('[data-element="slider"]', {
    slidesPerView: 1,
    modules: [Navigation, Pagination],
    loop: false,
    grabCursor: true,
    navigation: {
      nextEl: ".swiper__button-next.mod-slider",
      prevEl: ".swiper__button-prev.mod-slider",
    },
    pagination: {
      el: ".swiper-slider-pagination",
      type: "bullets",
      clickable: true,
      renderBullet: function (index, className) {
        return (
          '<div class="' +
          className +
          ' border-primary-300 bg-transparent border-2 w-6 h-6 rounded-full flex items-center justify-center opacity-100"><span class="rounded-full bg-transparent w-3 h-3"></span></div>'
        );
      },
    },
  });

  // slider gallery
  const sliderGallerys = document.querySelectorAll(".mod-slider.secondary");
  if (sliderGallerys) {
    sliderGallerys.forEach((slider) => {
      const currentSlider = slider.querySelector(
        '[data-element="slider-gallery"]',
      );
      new Swiper(currentSlider, {
        slidesPerView: 1,
        modules: [Navigation, Pagination],
        loop: false,
        grabCursor: true,
        spaceBetween: 30,
        breakpoints: {
          768: {
            slidesPerView: 1.5,
          },
          1024: {
            slidesPerView: 2.5,
          },
        },
        navigation: {
          nextEl: currentSlider.parentElement.querySelector(
            ".swiper__button-next.mod-slider",
          ),
          prevEl: currentSlider.parentElement.querySelector(
            ".swiper__button-prev.mod-slider",
          ),
        },
      });
    });
  }

  // cookie banner link prevent default
  const cookieLink = document.querySelector(".cc-custom-revoke");
  if (cookieLink) {
    cookieLink.addEventListener("click", (event) => {
      event.preventDefault();
    });
  }

  // scroll to top
  const totopBtn = document.querySelector("#totop");
  if (totopBtn) {
    window.addEventListener("scroll", () => {
      const scrollTop = window.scrollY || document.documentElement.scrollTop;
      totopBtn.classList.toggle("is-shown", scrollTop > 50);
    });

    totopBtn.addEventListener("click", handleScrollToTop);
  }

  // sticky header
  const stickyHeader = document.querySelector(".f-header--sticky");
  if (stickyHeader) {
    new StickyHeader(stickyHeader);
  }

  // blog header
  const blogHeader = document.querySelector(
    "body.blog.view-article .swiper-text__inner",
  );

  if (blogHeader) {
    blogHeader.classList.add("flex", "items-center", "justify-center", "gap-4");
    blogHeader.insertAdjacentHTML(
      "afterbegin",
      '<a href="/aktuelles" class="blog-header-link"><svg class="w-8 h-8" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-left-circle"><circle cx="12" cy="12" r="10"></circle><polyline points="12 8 8 12 12 16"></polyline><line x1="16" y1="12" x2="8" y2="12"></line></svg></a>',
    );
  }

  const toggleNav = document.querySelector(".toggle-nav .anim-menu-btn");
  if (toggleNav) {
    toggleNav.addEventListener("click", () => {
      document.body.classList.toggle("menu-open");
    });

    window.addEventListener("scroll", () => {
      const scrollTop = window.scrollY || document.documentElement.scrollTop;
      toggleNav.parentElement.classList.toggle("hidden", scrollTop < 400);
    });
  }
})();
